import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import Accounts from "./modules/accounts";
import Reservations from "./modules/reservations";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    snackbar: { show: false, message: "ahmad", color: "success" },
  },
  mutations: {
    SET_SNACKBAR(state,data){
      state.snackbar = data;
    }
  },
  actions: {
    error_message({commit},msg){
      commit('SET_SNACKBAR',{show:true,message:msg,color:"#D32F2F"})
    },
    message({commit},msg){
      commit('SET_SNACKBAR',{show:true,message:msg,color:"#2E7D32"})
    }
  },
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    Accounts,
    Reservations
  },
});
