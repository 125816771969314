import Vue from "vue";
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize
  } from "vee-validate";
  import en from "vee-validate/dist/locale/ar.json";
  import * as rules from "vee-validate/dist/rules";
  Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
  });
  
  localize("en", en);
  
  // Install VeeValidate components globally
  Vue.component("ValidationObserver", ValidationObserver);
  Vue.component("ValidationProvider", ValidationProvider);