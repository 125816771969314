export default{
    ACCOUNTS(state,data){
        state.accounts = data;
    },
    ACCOUNT(state,data){
        state.account = data;
    },
    FORM(state,data){
        state.form = data;
    }
}