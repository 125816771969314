export default{
    PRE_REQUEST(state,data){
        state.reservations = data.reservations;
        state.classrooms = data.classrooms;
        state.activities = data.activities;
        state.birthday_data = {
            birthday_defult_cost:data.birthday_defult_cost,
            birthday_defult_count:data.birthday_defult_count,
            birthday_more_defult_cost:data.birthday_more_defult_cost,
            birthday_start_time:data.birthday_start_time,
            birthday_end_time:data.birthday_end_time
        };
        state.birthday_classroom = data.birthday_classroom;
        state.accounts = data.accounts
    },
    ACCOUNT(state,data){
        state.account = data;
    },
    FORM(state,data){
        state.form = data;
    },
    TYPE(state,data){
        state.type = data;
    },
    FILTER(state,data){
        state.classrooms = data.classrooms;
        state.activity_times = data.activity_times
    }
}