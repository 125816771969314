import JwtService from "@/core/services/jwt.service";


const state = {
  user: {
    id: 1,
    name: "User",
    // email: "",
  },
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUserPersonalInfo(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  
};

const mutations = {
  PROFILE(state, user) {
    state.user = user;
    state.isAuthenticated = true;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
