export default {
    accounts(state){
        return state.accounts;
    },
    account(state){
        return state.account;
    },
    form(state){
        return state.form;
    }
}