import apiService from "../../../core/services/api.service";

const resourceUrl = "/guest/reservations";
import moment from "moment";
export default {
  index({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("TYPE", data.type);
      apiService
        .query(`${resourceUrl}/pre_request`, data)
        .then((res) => {
          commit("PRE_REQUEST", res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  store({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post(resourceUrl, data)
        .then((res) => {
          dispatch("index",{type:data.reservation_type});
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      apiService
        .get(resourceUrl, id)
        .then((res) => {
          res.data.data._files = [];
          commit("ACCOUNT", res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  update({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .update(resourceUrl, data.id, data)
        .then((res) => {
          dispatch("index");
          resolve(res);
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },
  delete({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      apiService
        .delete(`${resourceUrl}/${id}`)
        .then((res) => {
          dispatch("index");
          resolve(res);
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },
  filter({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .query(`${resourceUrl}/filter`, data)
        .then((res) => {
          commit("FILTER", res.data.data);
          resolve(res);
        })
        .catch((err) => {
          commit("FILTER", {classrooms:[],activities_time:[]});
          reject(err);
        });
    });
  },
  clear({ commit },data) {
    // console.log(a);
    commit("FORM", {
      date: moment().format("YYYY-MM-DD"),
      time: "12:00",
      account_id: null,
      reservation_type: data.type,
      classroom_id: null,
      store: 0,
      activity_time_id: null,
      password: null,
      number_of_people:1
    });
  },
};
