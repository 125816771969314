export default {
    reservations(state){
        return state.reservations;
    },
    reservation(state){
        return state.reservation;
    },
    classrooms(state){
        return state.classrooms;
    },
    activities(state){
        return state.activities;
    },
    activity_times(state){
        return state.activity_times;
    },
    birthday_data(state){
        return state.birthday_data;
    },
    accounts(state){
        return state.accounts;
    },
    birthday_classroom(state){
        return state.birthday_classroom;
    },
    form(state){
        return state.form;
    }
}