import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/layout/Layout"),
      children: [
        {
          path: "/",
          name: "home",
          component: () => import("@/views/accounts")
        },
        {
          path: "/reservations",
          name: "reservations",
          component: () => import("@/views/reservations/index.vue")
        },
        {
          path: "/reservations-activities",
          name: "reservations-activities",
          component: () => import("@/views/reservations/activities.vue")
        },
        {
          path: "/reservations-birthday",
          name: "reservations-birthday",
          component: () => import("@/views/reservations/birthday.vue")
        },
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/error/Error-1.vue")
    }
  ],
  mode:"history"
});
