import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import ApiService from "@/core/services/api.service";
// import { VERIFY_AUTH } from "@/store/auth.module";
// import { RESET_LAYOUT_CONFIG } from "@/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";

// API service init
ApiService.init();

Vue.mixin({
  methods:{
    send_error(msg){
      store.dispatch('error_message',msg);
    },
    send_message(msg){
      store.dispatch('message',msg);
    }
  }
})

const eventBus = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");

export default eventBus;