import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import store from "@/store";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = "https://admin.keytalentcenter.com/api";

    Vue.axios.interceptors.request.use(
      function(config) {
        const token = JwtService.getToken();

        config.headers['Accept-Language'] = "ar";
        config.headers["Access-Control-Allow-Origin"] = "*"
        console.log(config.headers);
        // if (config.url[config.url.length-1] == '/') {
        //   config.url = config.url.slice(0, -1)
        // }
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      function(error) {
        return Promise.reject(error);
      }
    );
    Vue.axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (401 === error.response.status) {
          store.state.auth.isAuthenticated = false;
          JwtService.destroyToken();
        } else {
          return Promise.reject(error);
        }
      }
    );
  },

  query(resource, params) {
    return Vue.axios.get(resource, {params:params})
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`)
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource)
  },
};

export default ApiService;
