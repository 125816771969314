import ApiService from "./api.service";

const ID_TOKEN_KEY = "id_token";
const LoginUrl = "/guest/generateToken";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const generateToken = () => {
    return new Promise((resolve, reject) => {
      ApiService.post(LoginUrl,{notification_token:"test"})
        .then((res) => {
          saveToken(res.data.data.token);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  
}

export default { getToken, saveToken, destroyToken,generateToken };
