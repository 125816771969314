import moment from "moment";

export default {
  reservations: [],
  reservation: {},
  classrooms: [],
  activities: [],
  activity_times: [],
  birthday_data: {
    birthday_defult_cost: 0,
    birthday_defult_count: 0,
    birthday_more_defult_cost: 0,
    birthday_start_time:"",
    birthday_end_time:""
  },
  accounts: [],
  birthday_classroom: { id: null, name: "" },
  form:{
    date: moment().format("YYYY-MM-DD"),
    time: "12:00",
    account_id: null,
    reservation_type: 1,
    classroom_id: null,
    store: 0,
    activity_time_id: null,
    password: null,
    number_of_people:1
  },
  type:null
};
