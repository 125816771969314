// China
export const locale = {
  pages: {
    users: "المستخدمين",
    roles: "الصلاحيات",
    accounts:"الحسابات"
  },
  menu: {
    managment: "الادارة",
    customers: "العملاء",
  },
  attributes: {
    name: "الاسم",
    role: "الصلاحية",
    username:"اسم المستخدم",
    password:"كلمة المرور",
    is_active:"مفعل",
    email:"البريد الالكتروني",
    phone_number:"رقم الهاتف",
    address:"العنوان",
    password_confirmation:"تأكيد كلمة المرور",
    created_at:'تاريخ الاضافة',
    updated_at:"تاريخ التعديل",
    created_by:"تم التعديل بواسطة",
    account_type:"توع الحساب",
    type:"النوع",
    accountType:"النوع",
    responsible_person_name:"اسم الشخص المسؤول",
    nationality:"الجنسية",
    residence:"الاقامة",
    father_name:"اسم الاب",
    phone_number2:"رقم الهاتف 2",
    city:"المدينة",
  },
  desctiptions:{
    add_user:"اضافة مستخدم جديد"
  },
  actions: "الاجرائيات",
  search:'بحث',
  add:"اضافة",
  edit:"تعديل",
  delete:"حذف",
  close:"اغلاق",
  save:"حفظ",
  add_user:"اضافة مستخدم",
  edit_user:"تعديل مستخدم",
  user:"مستخدم",
  active:"مفعل",
  inactive:"غير مفعل",
  accounts:"الحسابات",
  add_account:"اضافة حساب",
  edit_account:"تعديل حساب"

};
