import apiService from "../../../core/services/api.service";

const resourceUrl = "/guest/accounts";

export default {
  index({ commit }) {
    commit("ACCOUNTS", []);
    return new Promise((resolve, reject) => {
      apiService
        .query(resourceUrl)
        .then((res) => {
          commit("ACCOUNTS", res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  store({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post(resourceUrl, data)
        .then((res) => {
          dispatch("index");
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      apiService
        .get(resourceUrl, id)
        .then((res) => {
          res.data.data._files = [];
          commit("ACCOUNT", res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  update({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .update(resourceUrl, data.id, data)
        .then((res) => {
          dispatch("index");
          resolve(res);
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },
  delete({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      apiService
        .delete(`${resourceUrl}/${id}`)
        .then((res) => {
          dispatch("index");
          resolve(res);
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },
  clear({ commit }) {
    commit("FORM", { username: "", password: "" });
  },
};
