// China
export const locale = {
  pages: {
    users: "المستخدمين",
    roles: "الصلاحيات",
    accounts:"الحسابات",
    reservations:"الحجوزات",
    signs:"العلامات التجارية",
    reservations_enter_classroom:"حجوزات الدخول للقاعة"
  },
  menu: {
    managment: "الادارة",
    customers: "العملاء",
  },
  attributes: {
    name: "الاسم",
    role: "الصلاحية",
    username:"اسم المستخدم",
    password:"كلمة المرور",
    is_active:"مفعل",
    email:"البريد الالكتروني",
    phone_number:"رقم الهاتف",
    address:"العنوان",
    password_confirmation:"تأكيد كلمة المرور",
    created_at:'تاريخ الاضافة',
    updated_at:"تاريخ التعديل",
    created_by:"تم التعديل بواسطة",
    account_type:"توع الحساب",
    type:"النوع",
    accountType:"النوع",
    responsible_person_name:"اسم الشخص المسؤول",
    nationality:"الجنسية",
    residence:"الاقامة",
    father_name:"اسم الاب",
    phone_number2:"رقم الهاتف 2",
    city:"المدينة",
    title:"العنوان",
    number:"رقم",
    description:"توصيف",
    sign_state:'حالة العلامة',
    logo:"الشعار",
    name_ar:"الاسم العربي",
    name_en:"الاسم الاجنبي",
    sub_classes:"الفئات الفرعية",
    class:"الفئة",
    account:"العميل",
    available_amount:"الرصيد الحالي",
    enter_hours:"عدد الساعات داخل المركز",
    avatar:"الصورة الشخصية"
  },
  descriptions:{
    add_user:"اضافة مستخدم جديد",
    add_class:"اضافة فئة",
    add_sign:"اضافة علامة تجارية"
  },
  actions: "الاجرائيات",
  search:'بحث',
  add:"اضافة",
  edit:"تعديل",
  delete:"حذف",
  close:"اغلاق",
  save:"حفظ",
  add_user:"اضافة مستخدم",
  edit_user:"تعديل مستخدم",
  user:"مستخدم",
  active:"مفعل",
  inactive:"غير مفعل",
  accounts:"الحسابات",
  add_account:"اضافة حساب",
  edit_account:"تعديل حساب",
  classes:"الفئات",
  add_class:"اضافة فئة",
  edit_class:"تعديل فئة",
  signs:"العلامات",
  add_sign:"اضافة علامة",
  edit_sign:"تعديل علامة",


};
